<template>
  <div class="page">
    <SimpleHeader />

    <form class="form-recover-pwd text-center">
      <div class="card">
        <div class="card-body">
          <h1 class="h3 font-weight-normal">Definição de senha</h1>
          <p>Preencha os campos abaixo com sua nova senha de acesso.</p>

          <div class="row">
            <div class="col-md-6">
              <label for="inputSenha" class="sr-only">Senha</label>
              <input
                type="password"
                id="inputSenha"
                class="form-control mb-2"
                placeholder="Nova senha"
                v-model="senha"
                autofocus
                maxlength="20"
              />
            </div>
            <div class="col-md-6">
              <label for="confirmarSenha" class="sr-only"
                >Confirmar senha</label
              >
              <input
                type="password"
                id="confirmarSenha"
                class="form-control"
                placeholder="Confirme a nova senha"
                v-model="confirmarSenha"
                autofocus
              />
            </div>
          </div>

          <button
            @click.prevent="onClickConfirm"
            :disabled="loading"
            class="btn btn-success btn-block mt-2"
          >
            <span v-if="!loading">Confirmar</span>
            <span v-if="loading">
              <small class="text-purple">Aguarde...</small>
            </span>
          </button>
        </div>
      </div>
      <router-link to="/" class="mt-5 d-block"
        >Voltar para a tela inicial</router-link
      >
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import config from "../config";
import Auth from "../auth";
import captureError from "../helpers/captureError";
import SimpleHeader from "../components/SimpleHeader";

export default {
  components: {
    SimpleHeader,
  },
  data() {
    return {
      loading: false,
      senha: "",
      confirmarSenha: "",
      token: "",
    };
  },
  async created() {
    this.token = this.$route.query.p;

    if (!this.token) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "Este link de redefinição de senha está expirado. Por favor, solicite um novo link.",
      });

      //Desloga e leva para a tela de login
      Auth.logout();
      this.$router.replace("/login");
    }
  },
  methods: {
    async onClickConfirm() {
      if (!this.senha || !this.confirmarSenha) {
        return;
      }

      if (this.senha !== this.confirmarSenha) {
        Swal.fire({
          icon: "warning",
          title: "As senhas não conferem",
          text:
            "Verifique se você digitou corretamente a senha e a confirmação de senha.",
        });
        return;
      }

      if (this.senha.length < 6) {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Informe uma senha com no mínimo 6 dígitos.",
        });
        return;
      }

      this.loading = true;

      try {
        var data = {
          token: this.token,
          novaSenha: this.senha,
        };
        var response = await axios.post(
          `${config.API_URLV2}/auth/definirSenha`,
          data
        );

        await Swal.fire({
          icon: "success",
          title: "Senha definida com sucesso",
        });

        //Loga automaticamente e leva para a home
        Auth.setUserInfo(response.data);
        this.$router.replace("/cliente");
      } catch (e) {
        this.loading = false;

        if (e.response && e.response.data && e.response.data.expirado) {
          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "Este link de redefinição de senha está expirado. Por favor, solicite um novo link.",
          });

          //Desloga e leva para a tela de login
          Auth.logout();
          this.$router.replace("/login");
          return;
        }

        captureError(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível redefinir a senha. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style scoped>
.page {
  background: #f2f2f2;
  min-height: 100vh;
}
.form-recover-pwd {
  width: 100%;
  max-width: 500px;
  padding: 16px;
  margin: 0 auto;
  margin-top: 120px;
}
</style>
